import { Button, Grid, Box, Embed } from 'theme-ui';
import { Link } from 'components/atoms';
import * as React from 'react';
export default {
  Button,
  Grid,
  Box,
  Embed,
  Link,
  React
};