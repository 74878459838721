import { Heading, Text, Link } from 'components/atoms';
import { Grid, Box, Image as ThemeImage, Text as ThemeText } from 'theme-ui';
import { withPrefix } from 'gatsby';
import * as React from 'react';
export default {
  Heading,
  Text,
  Link,
  Grid,
  Box,
  ThemeImage,
  ThemeText,
  withPrefix,
  React
};