import { Embed } from 'theme-ui';
import { Image as ThemeImage, Text as ThemeText, Box } from 'theme-ui';
import { withPrefix } from 'gatsby';
import * as React from 'react';
export default {
  Embed,
  ThemeImage,
  ThemeText,
  Box,
  withPrefix,
  React
};