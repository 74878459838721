import { Flex, Image, Heading, Text, Link } from 'components/atoms';
import { Grid, Box } from 'theme-ui';
import * as React from 'react';
export default {
  Flex,
  Image,
  Heading,
  Text,
  Link,
  Grid,
  Box,
  React
};